import React from 'react'

const Footer = () => {
  return (
    <div className='footer'>
      <p>@Copyright Reserved by<a href="https://thearinfotech.com/" target='_blank'> Ar Infotech Solution</a></p>
    </div>
  )
}

export default Footer
