import React, { useRef, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import './InputField.css'
const InputField = () => {
  const [Items, setItems] = useState()
  const inputRaf = useRef(null)
  function convert() {
    let inputText = inputRaf.current.value;
    let words = inputText.toLowerCase().split(' ');
    for ( let i = 0; i<words.length; i++){
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    let capitalizedText = words.join(' ');
    setItems(capitalizedText)
  }

  function UpperCase() {
    let inputText = inputRaf.current.value;
    let word = inputText.toUpperCase();
    setItems(word)
  }
  function LowerCase() {
    let inputText = inputRaf.current.value;
    let word = inputText.toLowerCase();
    setItems(word)
  }


  return (
    <div className='container pt-5' id='main_div_cst'>
      <a href='/' className='btn btn-info'>Back To Home</a>
      <h2>Convert Text</h2>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia adipisci repudiandae cupiditate sunt aliquam, harum possimus fugit unde laborum, nisi quaerat ratione neque quibusdam iste? Maxime recusandae nobis reprehenderit eveniet.</p>
      <div className="form">
        <textarea type="text" name='text' className='form-control mb-4' ref={inputRaf}></textarea>
        <button className='btn  btn-success' onClick={() => convert()}>Capitalize</button>
        <button className='btn  btn-success' onClick={() => UpperCase()}>UpperCase</button>
        <button className='btn  btn-success' onClick={() => LowerCase()}>LowerCase</button>
        <p className='Value'>{Items}</p>
      </div>
    </div>
  )
}

export default InputField
