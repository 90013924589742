import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; // Import Route

import InputField from '../Components/InputField.jsx';
import Compression from '../Components/Compression.jsx';
import Home from '../Components/Home.jsx';

const WebRoutes = () => {
  return (
    <BrowserRouter basename="/"> {/* Set basename to "/my-app" */}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/convertor' element={<InputField />} />
        <Route path='/image-comparison' element={<Compression />} />
      </Routes>
    </BrowserRouter>
  );
};

export default WebRoutes;
