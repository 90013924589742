import React from 'react'
import AllInOneHome from '../Assets/allinonehome.png'

const Home = () => {
  return (
    <section id='home_custom'>
      <div className='container'>
        <div className="row">
          <div className="col-lg-6 mt-5">
            <h1 className=''>Welcome to <br /><span className="text_colo">All In One</span> Convertor</h1>
            <p className='mb-4'>Welcome to All In One Converter, your ultimate tool for seamless conversion of various file formats. With our user-friendly interface and powerful conversion algorithms, you can effortlessly convert between different types of files in just a few clicks.</p>
            <a href='/convertor' className='btn btn_cst bg-light'>Text Convertor</a><span className=""> </span>
            <a href='/image-comparison' className='btn btn-info btn_cst bg-dark text-white' >Image Comparsion</a>

            <h2 className='mt-5'>Need Help?</h2>
            <p>Our website isn't just a conversion tool; it's also a valuable resource hub designed to empower you with knowledge and guidance on all things related to file conversion.</p>
            <a href='/image-comparison' className='btn btn_cst' >Contact Us Now</a>
          </div>
          <div className="col-lg-6">
          <center>
    <dotlottie-player src="https://lottie.host/e81ef00e-efab-4e8d-868a-50b3831973a2/aFPUlDdVUT.json" background="transparent" speed="1"  loop autoplay></dotlottie-player>
      </center>

          </div>
        </div>
      </div>
    </section>
  )
}

export default Home
