import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import imageCompression from 'browser-image-compression';
import JSZip from 'jszip';

const Compression = () => {
    const [imageUrls, setImageUrls] = useState([]);
    const [loading, setLoading] = useState(false);
    const [names, setNames] = useState([]);

    const handleImage = async (event) => {
        setLoading(true);
        const files = event.target.files;
        const compressedImageUrls = [];
        const fileNames = [];

        for (let i = 0; i < files.length; i++) {
            const Imagefile = files[i];
            const imageUrl = URL.createObjectURL(Imagefile);
            fileNames.push(Imagefile.name);

            if (Imagefile) {
                const fileSizeByte = Imagefile.size;
                const fileSizeKb = fileSizeByte / 1024;
                const fileSizeMb = fileSizeKb / 1024;

                let imageSize;
                if (fileSizeMb < 1) {
                    imageSize = `${fileSizeKb.toFixed(2)} KB`;
                } else {
                    imageSize = `${fileSizeMb.toFixed(2)} MB`;
                }

                const options = {
                    maxSizeMB: 0.5,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true,
                    quality: 0.9
                };

                try {
                    const compressedFile = await imageCompression(Imagefile, options);
                    const compressedImageUrl = URL.createObjectURL(compressedFile);
                    const compressedSizeKb = (compressedFile.size / 1024).toFixed(2);
                    const compressedSize = compressedSizeKb < 1 ? `${compressedSizeKb} KB` : `${(compressedSizeKb / 1024).toFixed(2)} MB`;
                    compressedImageUrls.push({ originalUrl: imageUrl, compressedUrl: compressedImageUrl, size: imageSize, compressedSize: compressedSize });
                } catch (error) {
                    console.log(error);
                }
            }
        }

        setImageUrls(compressedImageUrls);
        setNames(fileNames);
        setLoading(false);
    };

    const download = (url, index) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = names[index] + ".png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const downloadAll = () => {
        const zip = new JSZip();
        const folder = zip.folder("compressed_images");

        imageUrls.forEach((image, index) => {
            fetch(image.compressedUrl)
                .then(res => res.blob())
                .then(blob => {
                    folder.file(`${names[index]}_.png`, blob);
                    if (index === imageUrls.length - 1) {
                        folder.generateAsync({ type: "blob" }).then(content => {
                            const link = document.createElement('a');
                            link.href = URL.createObjectURL(content);
                            link.download = "allinoone.zip";
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        });
                    }
                })
                .catch(error => console.log(error));
        });
    };
 
    return (
        <div className='container p-5 bg-light' id='main_div_cst'>
            <a href='/' className='btn btn-info'>Home</a><span> </span>
            <a href='/convertor' className='btn btn-info'>Text Convertor</a>
            <h1 className='text-center'>Image Compression</h1>
            <p className='text-center'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ducimus, officia. Reprehenderit, adipisci neque rem voluptatem incidunt facere ab perspiciatis enim culpa blanditiis repellat iure recusandae quibusdam reiciendis ipsam dolor aliquid.</p>
            <input type="file" multiple accept='image/*' onChange={handleImage} className='form-control p-3' />
            {imageUrls && (
                    <button className='btn btn-success mt-5' onClick={downloadAll}>Download All</button>
                    )}
            {loading ? (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
            ) : (
                <div>
                    {imageUrls.map((image, index) => (
                        <div key={index} className='item'>
                            <img src={image.compressedUrl} width='100' alt="" />
                            <p>Original Image Size: {image.size}</p>
                            <p>Compressed Image Size: {image.compressedSize}</p>
                            <button className='btn btn-success' onClick={() => download(image.compressedUrl, index)}>Download</button>
                        </div>
                    ))}
                    
                </div>
            )}
        </div>
    );
}

export default Compression;
